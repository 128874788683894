.pages {
  text-align: center;
  overflow: scroll;
  height: 100vh;
  scroll-snap-type: y mandatory;
}

.page {
  height: 100vh;
  scroll-snap-align: start;
}

.card {
  width: 15vh;
  height: 15vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  font-size: 24px;
  background-color: #c2c2c2;
}